<template>
  <div class="ct-home page-author">
    <!-- <div class="dc-header ct-home-content">
      <b-container fluid class="d-flex align-items-center container">
        <router-link :to="{ name: 'store' }">
          <div>ショップ</div>
        </router-link>
        <b-icon icon="chevronRight" class="mx-2"></b-icon>
        <div>著者一覧</div>
      </b-container>
    </div>
    <div class="ct-filter">
      <div class="container">
        <div class="row align-items-center">
          <h5 class="col-sm-12 font-weight-bold my-4 font-title">著者一覧</h5>
        </div>
      </div>
    </div> -->
    <div class="mask">
      <h2>著者リスト</h2>
    </div>
    <!-- <div class="ct-home-content"> -->
    <!-- <div class="container"> -->
    <!-- <b-row v-if="listAuthor.data && listAuthor.data.length">
          <b-col
            v-for="(value, index) in listAuthor.data"
            :key="index"
            class="mb-5"
            cols="12"
            md="4"
            lg="3"
            sm="2"
          >
            <div
              class="ct-home-card border-0"
              @click="returnDetailAuthor(value.id)"
            >
              <div
                class="card-img"
                :class="{
                  'd-flex  align-items-center justify-content-center img-content-author':
                    !value.avatar,
                }"
              >
                <img
                  v-if="value.avatar"
                  :src="`${urlBackend}/${value.avatar}`"
                  alt=""
                />
                <div
                  class="d-flex align-items-center justify-content-center img-author"
                  v-else
                >
                  <h5 class="font-weight-bold text-truncate-title text-center">
                    {{ value && value.name }}
                  </h5>
                </div>
              </div>
              <div
                class="card-name px-3 pt-3 pb-3 text-center font-weight-bold"
              >
                <router-link
                  class="text-decoration-none font-title-default"
                  :to="{ name: 'author detail', params: { id: value.id } }"
                  ><p class="text-truncate-title">
                    {{ value.name }}
                  </p></router-link
                >
              </div>
            </div>
          </b-col>
        </b-row> -->
    <div
      class="inner lineHeight"
      v-if="listAuthor.data && listAuthor.data.length"
    >
      <div class="allList listBox">
        <ul class="authorIndex allIndex">
          <li v-for="(value, index) in listAuthor.data" :key="index">
            <router-link
              class="text-decoration-none"
              :to="{
                name: $route.params.shopId
                  ? 'author detail'
                  : 'author detail domain',
                params: { id: value.id },
              }"
            >
              <figure>
                <span class="imgWrap">
                  <img
                    width="150"
                    height="150"
                    v-if="value.avatar"
                    :src="`${urlBackend}/${value.avatar}`"
                    class="attachment-thumbnail size-thumbnail wp-post-image"
                    alt=""
                    loading="lazy"
                    sizes="(max-width: 150px) 100vw, 150px"
                  />
                  <img v-else :src="`${no_image}`" />
                </span>
                <figcaption>
                  <div class="name">
                    <div>{{ value.name }}</div>
                  </div>
                  <div class="position pcOnly">
                    <div>
                      {{ value && value.semi_title && value.semi_title }}
                    </div>
                  </div>
                </figcaption>
              </figure>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <b-row v-else class="align-items-center justify-content-center">
      <h3 v-if="listAuthor.data" class="font-weight-bold">
        著者は存在しません。
      </h3>
    </b-row>
    <div class="d-flex justify-content-center position-relative m-auto">
      <CPagination
        v-if="listAuthor.data && listAuthor.last_page > 1"
        :activePage.sync="page"
        :pages="listAuthor.last_page"
        align="center"
      />
    </div>
    <!-- </div> -->
    <!-- </div> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import no_image from "@/assets/img/mem.png";
import { Constants } from "../../utils/constants";
export default {
  name: "ListAuthorStore",
  components: {
    // Tables,
  },
  data() {
    return {
      urlBackend: Constants.URL_BE,
      user_normal: localStorage.getItem(Constants.NORMAL_USER_INFO)
        ? JSON.parse(localStorage.getItem(Constants.NORMAL_USER_INFO))
        : null,
      no_image: no_image,
      active_el: 0,
      listAuthor: [],
      page: 1,
      shop_id: localStorage.getItem(Constants.SHOP_ID),
    };
  },
  created() {
    const request = {
      shop_id: this.shop_id,
      page: this.page,
      limit: Constants.LIMIT_AUTHOR,
    };
    this.getListAuthorFilter(request);
  },
  computed: {
    ...mapGetters([
      "listAuthorFilter",
      "contentById",
      "message",
      "success",
      "error",
    ]),
  },
  watch: {
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["success", false]);
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
    listAuthorFilter() {
      this.listAuthor = [];
      this.listAuthor = this.listAuthorFilter;
    },
    page() {
      const request = {
        shop_id: this.shop_id,
        page: this.page,
        limit: Constants.LIMIT_AUTHOR,
      };
      this.getListAuthorFilter(request);
    },
  },
  methods: {
    ...mapActions({ getListAuthorFilter: "getListAuthorFilter" }),
    returnDetailAuthor(id) {
      this.$router.push({
        name: this.$route.params.shopId
          ? "author detail"
          : "author detail domain",
        params: { id: id },
      });
    },
  },
};
</script>
