var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ct-home page-author"},[_vm._m(0),(_vm.listAuthor.data && _vm.listAuthor.data.length)?_c('div',{staticClass:"inner lineHeight"},[_c('div',{staticClass:"allList listBox"},[_c('ul',{staticClass:"authorIndex allIndex"},_vm._l((_vm.listAuthor.data),function(value,index){return _c('li',{key:index},[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{
              name: _vm.$route.params.shopId
                ? 'author detail'
                : 'author detail domain',
              params: { id: value.id },
            }}},[_c('figure',[_c('span',{staticClass:"imgWrap"},[(value.avatar)?_c('img',{staticClass:"attachment-thumbnail size-thumbnail wp-post-image",attrs:{"width":"150","height":"150","src":`${_vm.urlBackend}/${value.avatar}`,"alt":"","loading":"lazy","sizes":"(max-width: 150px) 100vw, 150px"}}):_c('img',{attrs:{"src":`${_vm.no_image}`}})]),_c('figcaption',[_c('div',{staticClass:"name"},[_c('div',[_vm._v(_vm._s(value.name))])]),_c('div',{staticClass:"position pcOnly"},[_c('div',[_vm._v(" "+_vm._s(value && value.semi_title && value.semi_title)+" ")])])])])])],1)}),0)])]):_c('b-row',{staticClass:"align-items-center justify-content-center"},[(_vm.listAuthor.data)?_c('h3',{staticClass:"font-weight-bold"},[_vm._v(" 著者は存在しません。 ")]):_vm._e()]),_c('div',{staticClass:"d-flex justify-content-center position-relative m-auto"},[(_vm.listAuthor.data && _vm.listAuthor.last_page > 1)?_c('CPagination',{attrs:{"activePage":_vm.page,"pages":_vm.listAuthor.last_page,"align":"center"},on:{"update:activePage":function($event){_vm.page=$event},"update:active-page":function($event){_vm.page=$event}}}):_vm._e()],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mask"},[_c('h2',[_vm._v("著者リスト")])])
}]

export { render, staticRenderFns }